




































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { Presentation, Session } from "@/core/models";

// Components
import {
  ManagedUsers,
  SessionFilters,
  SessionDetails,
  SessionsProgressPage,
} from "@/components/sessions";
import SessionsSelect from "./components/SessionsSelect.vue";
import SessionsToggle from "./components/SessionsToggle.vue";
import ProgressHeader from "./components/ProgressHeader.vue";
import SessionLimitControl from "./components/SessionLimitControl.vue";

@Component({
  components: {
    ManagedUsers,
    SessionFilters,
    SessionsSelect,
    SessionDetails,
    SessionsToggle,
    ProgressHeader,
    SessionLimitControl,
    SessionsProgressPage,
  },
})
export default class Sessions extends Vue {
  @Getter("theme") theme!: any;
  @Getter("sessions/loading") loading!: boolean;
  @Getter("sessions/retrieved") retrieved!: boolean;
  @Getter("sessions/getSessions") sessions!: Session[];
  @Getter("sessions/removingSession") removingSession!: boolean;
  @Action("currentSession/setSession") setCurrentSession!: Function;
  @Action("sessions/getSlimSessions") getSlimSessions!: () => Promise<void>;
  @Action("sessions/getSingleSession")
  getSingleSession!: (i: number) => Promise<void>;

  @Getter("presentations/loading") presLoading!: boolean;
  @Getter("presentations/retrieved") presRetrieved!: boolean;
  @Getter("presentations/getPresentations") presentations!: Presentation[];
  @Action("presentations/getPresentations")
  getPresentations!: () => Promise<void>;

  @Getter("currentSession/id") selectedSessionID!: number;

  selectedTab = 0;
  selectedSession = Session.Empty();
  filteredSessions: Session[] = [];
  limit = this.sessions ? this.sessions.length : 0;

  applyFilter(filtered: Session[]) {
    this.filteredSessions = filtered;
    if (filtered.length === 0) {
      this.setCurrentSession(null);
      return;
    }
    if (!filtered.some(x => x.ID === this.selectedSessionID)) {
      const first = filtered[0];
      this.setCurrentSession(first);
      if (!first.Loaded) this.getSingleSession(first.ID);
    }
  }

  @Watch("loading", { immediate: true })
  sessonsLoaded() {
    this.filteredSessions = this.sessions;
    if (this.sessions.length > 0) this.setCurrentSession(this.sessions[0]);
  }

  @Watch("$route", { immediate: true })
  routeChanged() {
    if (this.$route.path.endsWith("list")) this.selectedTab = 0;
    else if (this.$route.path.endsWith("progress")) this.selectedTab = 1;
    this.applyFilter(this.sessions);
  }

  get reducedSessions() {
    return this.filteredSessions.slice(0, this.limit);
  }

  @Watch("sessions", { immediate: true })
  sessionsChanged(newSessions: Session[], _oldSessions: Session[]) {
    if (newSessions.length === 0) return;
    let session = this.sessions.find(x => x.ID === this.selectedSessionID);
    if (!session) {
      session = this.filteredSessions[0];
      this.setCurrentSession(session);
    }
    if (!session.Loaded) return this.getSingleSession(session.ID);
    this.setCurrentSession(session);
    this.filteredSessions = this.sessions;
    this.limit = this.sessions.length - (this.sessions.length % 2);
  }

  created() {
    if (!this.retrieved) this.getSlimSessions();
    if (!this.presRetrieved) this.getPresentations();
  }
}
